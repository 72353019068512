import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const MarshmallowTower = () => (
  <Layout
    title="Marshmallow Tower - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">Marshmallow Tower</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Marshmallow Tower</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link
                to="../make-your-own-cotton-ball-launcher"
                className="previous"
              >
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="edf6dd2d-2845-490c-90aa-1e8839328298"
                className="de-vid"
              ></Video>
              <Link to="../c2-balloon" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Have you ever wondered how skyscrapers can be so tall? Or how
              people build bridges to span long distances? We use engineering to
              come up with ways to build tall, long, and sturdy structures.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              The first building that was considered a skyscraper was the Home
              Insurance Building in Chicago, Illinois. It was built in 1885, and
              stood until 1931. It was considered a skyscraper because it was
              the first building to use steel and concrete as its foundation and
              structure. However, people have been building tall towers since
              300 BCE! The lighthouse of Alexandria was about 330 feet tall and
              stood from 280 BCE until 1480 CE. In this activity, you won’t have
              to create something that big, but if you want to look at how those
              structures were designed, they might inspire your creation using
              only marshmallows and uncooked spaghetti.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>A box of uncooked spaghetti</li>
              <li>A bag of marshmallows (small or standard size)</li>
              <li>A ruler</li>
              <li>A place to work that can get messy</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>A box of uncooked spaghetti</li>
              <li>A bag of marshmallows (small or standard size)</li>
              <li>A ruler</li>
              <li>A place to work that can get messy</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>
                Make sure you have a clean workspace, and open up the spaghetti
                and the marshmallows.
              </li>
              <li>
                Your goal is to create a marshmallow and spaghetti tower that is
                at least 6 inches tall from base to top. Take a moment to think
                about what the best way to do this will be. What will help the
                tower be sturdy? What will help it be stable?
              </li>
              <li>
                Use the marshmallows as joints, and the spaghetti as beams, and
                try to build the tallest tower that you can. Use your ruler to
                measure how tall you are building.
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <ul>
              <li>
                What happens when you use a square base vs. a triangle base?
              </li>
              <li>What happens as the tower gets taller?</li>
              <li>What happens when you use shorter spaghetti?</li>
              <li>
                What are some of the ways you could strengthening your tower
                using other materials?
              </li>
            </ul>
            <p>
              You will probably find that the most effective towers have wide,
              sturdy bases, use shorter pieces of pasta, and use triangles and
              pyramids as support structures. Triangles are a great structure to
              make, because they are the only shape where you cannot change the
              angles that the sides make without changing the length of the
              sides. This makes them very sturdy and able to support things
              well. Having a wide base gives the tower lots of stability and
              allows for better weight distribution.
            </p>
            <p>
              Try adding a challenge for yourself by creating your tower for a
              specific purpose. Can you make a tower that holds an egg 6 inches
              off the table? How about a book? How tall can you make a tower
              using only unbroken pieces of spaghetti? Keep experimenting with
              different ways to build.
            </p>
            <p>
              Make sure to clean up when you are done. Compost or throw away the
              marshmallows and pasta, and clean up your workspace.
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            {/* <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    ETS1.A: Defining and Delimiting Engineering Problems
                  </label>
                  <div className="content">Content coming soon...</div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    ETS1.B: Developing Possible Solutions
                  </label>
                  <div className="content">Content coming soon...</div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    ETS1.C: Optimizing the Design Solution
                  </label>
                  <div className="content">Content coming soon...</div>
                </div>
              </div>
            </div> */}
          </Column>
          {/* Commenting out profiles for a client review
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="MarshmellowTower-Profile-Square.png" />
              </div>
              <div className="global__profile-content">
                <h3>Mary Caruso Daily</h3>
                <h4> Advanced Product Development Specialist, 3M</h4>
              </div>
            </div>
          </Column>  */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="MakeYourOwnCotton-Thumbnail.png" />
              <div className="card__content">
                <h3>Make Your Own Cotton-Ball Launcher</h3>
                <h4>
                  What can a rubber band and a cotton ball teach you about
                  potential and kinetic energy?
                </h4>
                <p>
                  Join Michael Lewandowski to make your own cotton-ball launcher
                  and find out!
                </p>
              </div>
              <Link
                to="../make-your-own-cotton-ball-launcher"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="C02Balloon-Thumbnail.png" />
              <div className="card__content">
                <h3>
                  C0<sub>2</sub> Balloon
                </h3>
                <h4>
                  Baking soda and acid reactions in baking make things puff and
                  rise.
                </h4>
                <p>
                  But how could you use this same chemical reaction to blow up a
                  balloon?
                </p>
              </div>
              <Link to="../c2-balloon" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="ElephantToothpaste-Thumbnail.png" />
              <div className="card__content">
                <h3>Elephant Toothpaste</h3>
                <p>
                  Join Camille Schrier, a scientist who was crowned Miss America
                  2020, as she shows you how to make a chemical reaction so big
                  it’s fit for elephants!
                </p>
              </div>
              <Link
                to="../elephant-toothpaste"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default MarshmallowTower;
